
import { defineComponent, ref } from 'vue'
import usePendingApproval from "@/hooks/checking/spotChecking/pendingApproval/usePendingApproval";
import { ColumnProps } from 'ant-design-vue/es/table/interface';
type Key = ColumnProps['key'];
const tableWidth = window.innerWidth
const tableHeight = window.innerHeight-325
import TableWrapper from "@/components/TableWrapper.vue";
// import YearPicker from '@/views/Payment/components/YearPicker.vue'
import ApprovalModal from "@/views/Payment/components/approvalModal.vue";
import { useStore } from "vuex";
export default defineComponent({
  name: 'payoutPreparation',
  components: {
    // Pagination,
    TableWrapper,
    ApprovalModal
    // YearPicker
  },
  props: {},
  setup() {
    const store = useStore()
    const {
      columnsData, tableData, rowSelection, pageChange, sizeChange, pageSize, pageNumber, total,
      payoutRoundClick, downloadClick, approveClick, rejectClick, approvalModalVisible, confirmClick
    } = usePendingApproval()
    const handlePageChange = (page: number,pageSize: number) =>{
      pageChange(page, pageSize)
    }
    const handleSizeChange = (page: number,pageSize: number) =>{
      sizeChange(page, pageSize)
    }
    
    return {
      store,
      // searchParams,
      // doSearch,
      // doReset,
      tableWidth,
      tableHeight,
      columnsData,
      tableData,
      rowSelection,
      pageSize,
      pageNumber,
      total,
      handlePageChange,
      handleSizeChange,
      payoutRoundClick,
      downloadClick,
      approveClick,
      rejectClick,
      approvalModalVisible,
      confirmClick
    }
  }
})
