import { ref, reactive, computed, onMounted } from "vue";
import * as API from "@/API/checking/spotChecking";
import useThousandsAndTwoDecimal from "@/utils/payment/useThousandsAndTwoDecimal";
import useThousands from "@/utils/payment/useThousands";
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import downloadFile from '@/utils/payment/downloadFile'
import { message } from 'ant-design-vue';
import { ColumnProps } from 'ant-design-vue/es/table/interface';

type Key = ColumnProps['key'];

// type SearchParams = {
//     year: Moment | null;
//     month: Moment | null;
// }

export interface TableData {
    payoutRoundId: string;
    payoutRound: string;
    programNo: string;
    dealerNo: string;
    vinNo: string;
    amountWVat: string;
    amountWOVat: string;
    payoutRoundStatus: string;
    statusUpdateDate: string;
}

const useVehicleNameSet = () => {
    const { commit } = useStore()
    const router = useRouter()

    // const searchParams = reactive<SearchParams>({
    //     year: null,
    //     month: null
    // })
    const total = ref<number>(0)
    const pageNumber = ref<number>(0)
    const pageSize = ref<number>(10)
    const columnsData = computed(() => {
        return [
            { title: "Payout Round", dataIndex: "payoutRound",slots: { customRender: 'payoutRound' }, width: 150 },
            { title: "Program No.", dataIndex: "programNo", customRender: useThousands(), align: 'right' ,width: 140},
            { title: "Dealer No.", dataIndex: "dealerNo", customRender: useThousands(), align: 'right',width: 140 },
            { title: "VIN No.", dataIndex: "vinNo", customRender: useThousands(), align: 'right' ,width: 140},
            { title: "Amount w VAT", dataIndex: "amountWVat", customRender: useThousandsAndTwoDecimal(), width: 190, align: "right"},
            { title: "Amount w/o VAT", dataIndex: "amountWOVat", customRender: useThousandsAndTwoDecimal(), width: 190, align: "right"},
            { title: "Status", dataIndex: "payoutRoundStatus", width: 200, align: 'center'},
            { title: "Last Update Date", dataIndex: "statusUpdateDate", slots: { customRender: 'statusUpdateDate' },width: 150},
            { title: "Operation", dataIndex: "operation",slots: { customRender: 'operation' }, align: 'center',width: 90 }
        ]
    })
    const tableData = ref<TableData[]>()
    const rowSelection = reactive<{ selectedRowKeys: Key[]; selectedRowData: TableData[]; onChange: Function;columnWidth: string; type: string }>({
        onChange: (selectedRowKeys: Key[], selectedRows: TableData[]) => {
            rowSelection.selectedRowKeys = selectedRowKeys;
            rowSelection.selectedRowData = selectedRows;
        },
        selectedRowKeys: [],
        selectedRowData: [],
        columnWidth: '30px',
        type: 'radio'
    })
    const getTableData = () => {
        // pendingOrReview:5 pending 4 review
        // const params = {
        //     pendingOrReview: 5,
        //     roundMonth: searchParams.month ? searchParams.month.format('M') : '',
        //     roundYear: searchParams.year ? searchParams.year.format('YYYY') : ''
        // }
        API.getPendingList().then((data: any) => {
            tableData.value = data
            // total.value = data.totalElements
        })
    }
    const doSearch = () => {
        console.log('search按钮')
        pageNumber.value = 1
        getTableData()
    }
    const pageChange = (page: number, size: number) => {
        pageNumber.value = page > 0 ? page : 1
        pageSize.value = size
        getTableData()
    };
    const sizeChange = (page: number, size: number) => {
        pageNumber.value = page > 0 ? page : 1
        pageSize.value = size
        getTableData()
    }
    const payoutRoundClick = (record: any) => {
        if (record.type !== 'DEALER') return
        const info = {
            payoutRoundId: record.payoutRoundId,
            payoutRound: record.payoutRound,
            status: record.payoutRoundStatus
        }
        console.log(info)
        commit('spotChecking/updatePayoutRoundData', info)
        router.push({ path: '/spotChecking/pendingApproval/payoutRound' })
    }
    const downloadClick = (record: TableData) => {
        const params = {
            url: '/claimapi/pendingApprovalAndReview/export?payoutRound=' + record.payoutRoundId,
            method: 'get'
        }
        downloadFile(params)
        // .catch(msg =>
        //     message.warning(msg)
        // )
    }
    // const getSearchParams = () => {
    //     API.getDefaultYearAndMonth().then((data: any) => {
    //         searchParams.year = moment(data.year.toString())
    //         searchParams.month = moment(data.year+'-'+(data.month>10?data.month:'0'+data.month))
    //         doSearch()
    //     })
    // }
    // const doReset = () => {
    //     Object.assign(searchParams, {
    //         year: null,
    //         month: null
    //     })
    //     tableData.value = []
    //     getSearchParams()
    // }
    const approveFunc = () => {
        const params = {
            payoutRoundId: rowSelection.selectedRowKeys[0]
        }
        API.approveList(params).then((res: any) => {
            if (res.code === '0') {
                message.success('Sucessfully Approve!')
                getTableData() 
            } else {
                message.error(res.message)
            }
        })
    }
    const approvalModalVisible = ref(false)
    const confirmClick = (comment: string, next: Function) => {
        const params = {
            comment,
            payoutRoundId: rowSelection.selectedRowKeys[0]
        }
        API.rejectList(params).then(() => {
            message.success('Sucessfully Reject!')
            approvalModalVisible.value = false
            next()
            rowSelection.selectedRowKeys = []
            rowSelection.selectedRowData = []
            getTableData()
        }).catch(() => {
            next()
        })
    }
    const approveClick = () => {
        if (rowSelection.selectedRowKeys.length === 0) {
            message.warning('Please select the data first')
            return
        }
        approveFunc()
    }
    const rejectClick = () => {
        if (rowSelection.selectedRowKeys.length === 0) {
            message.warning('Please select the data first')
            return
        }
        approvalModalVisible.value = true
    }
    onMounted(() => {
        doSearch()
    })
    return {
        // searchParams,
        columnsData,
        tableData,
        rowSelection,
        doSearch,
        // doReset,
        pageChange,
        sizeChange,
        pageSize,
        pageNumber,
        total,
        payoutRoundClick,
        downloadClick,
        approveClick,
        rejectClick,
        approvalModalVisible,
        confirmClick
    }
}
export default useVehicleNameSet